body {
	margin: 0;
	/* height: 100vh; */
}

#root {
	position: relative;
}

.leaflet-container {
	width: 100%;
	height: 400px;
}